
import { Options, Vue, prop } from "vue-class-component";
import ModalShowImage from "@/app/ui/components/modal-show-image/index.vue";
import { UploadMultiple } from "@/domain/entities/MainApp";
import watermark from "watermarkjs";
import { MainAppController } from "../../controllers/MainAppController";

class Props {
  modelValue = prop<any>({
    required: true
  });
  title = prop<string>({
    default: "Klik",
    type: String
  });
  maxSize = prop<number>({
    default: 256,
    type: Number
  });
  multipleIndex = prop<number>({
    default: 0,
    type: Number
  });
  defaultImage = prop<string>({
    default: "",
    type: String
  });
  watermark = prop<string>({
    default: "",
    type: String
  })
  titleSize = prop<string>({
    default: '14',
    type: String
  })
  widthContentCard = prop<string>({
    default: "",
    type: String
  })
  accept = prop<string>({
    default: "image/png, image/jpg, image/jpeg, application/pdf",
    type: String
  })
  widthImage = prop<string>({
    default: "w-24",
    type: String
  })
  disabled= prop<boolean>({
    default: false,
    type: Boolean
  })
}

@Options({
  emits: ["upload", "remove"],
  components: {
    ModalShowImage
  },
})
export default class UploadImageTiny extends Vue.with(Props) {
  isDataValidate = false;
  isDragFile = false;
  fileOriginal = "";
  isType = "";

  $refs!: {
    file: HTMLFormElement;
  };

  setDragAndDrop(e: any, value: boolean) {
    this.isDragFile = value;
    e.preventDefault();
  }

  get isTypePdf() {
    return this.isType === "pdf";
  }

  onUploadImage(file: any) {
    const type = file.name.split(".").pop().toLowerCase();
    this.isType = type;
    if (this.validateFileType(type)) {
      if (file.size > this.maxSize * 1024 * 1024) this.setError();
      else {
        this.fileOriginal = type === "pdf" ? "" : URL.createObjectURL(file);
        this.$emit("update:modelValue", file);
        this.isDataValidate = false;
        this.isDragFile = false;
        if (this.watermark) {
          MainAppController.showLoading();
          const img = new Image();
          img.src = this.fileOriginal;

          // Wait for the image to load to get its dimensions
          img.onload = () => {
            const dynamicFontSize = `${img.width * 0.1}px`; // Set font size to 15% of the image width

            watermark([this.fileOriginal])
              .image(
                watermark.text.center(
                  this.watermark,
                  `${dynamicFontSize} Montserrat extrabold`,
                  "#a7a8aa"
                )
              )
              .then((img: any) => {
                fetch(img.src)
                  .then(res => res.blob())
                  .then(async blob => {
                    const fileWatermark = new File([blob], file.name, blob);
                    const compressedFileWatermarkBlob = await this.imageCompression(
                      fileWatermark
                    );
                    const compressedFileWatermark = new File(
                      [compressedFileWatermarkBlob],
                      file.name,
                      compressedFileWatermarkBlob
                    );
                    this.fileOriginal = URL.createObjectURL(
                      compressedFileWatermark
                    );
                    MainAppController.closeLoading();
                    this.uploadIntoStorage(compressedFileWatermark);
                  });
              });
          };
        } else {
          this.uploadIntoStorage(file);
        }
      }
    } else this.setDefault();
  }
  imageCompression(image: any): any {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(image)
                reader.onload = (event: any) => {
                const img = new Image()
                img.src = event.target.result
                img.onload = (e: any) => {
                    const elem = document.createElement('canvas');
                    const scaleFactor = 0.65;
                    elem.height = scaleFactor * e.target.height
                    elem.width = e.target.width * scaleFactor
                    const ctx = elem.getContext('2d')
                    ctx?.drawImage(e.target, 0, 0, e.target.width * scaleFactor, e.target.height * scaleFactor)
                    ctx?.canvas.toBlob(
                        (blob) => {
                            resolve(blob)
                        },
                        'image/webp',
                        1
                    )
                }
                }
            })
        }

  validateFileType(type: string) {
    return type === "jpg" || type === "png" || type === "jpeg" || type === "pdf";
  }

  onSubmitClick(e: any) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.onUploadImage(file);
    } else this.setDefault();
  }

  onSubmitDrag(e: any) {
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer && e.dataTransfer.files[0];
      this.onUploadImage(file);
    } else this.setDefault();
  }

  setDefault() {
    this.$emit("update:modelValue", "");
    this.uploadIntoStorage("");
    this.deleteFromStorage();
    const refValue = this.$refs.file;
    if (refValue) {
      refValue.value = ""
    }
    this.isDragFile = false;
  }

  setError() {
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  uploadIntoStorage(file: any) {
    this.$emit("upload", new UploadMultiple({
      index: this.multipleIndex,
      file: file
    }))
  }

  deleteFromStorage() {
    this.$emit("remove", this.multipleIndex)
  }

  get typeFileDefaultIsPdf() {
    const formatSplited = this.defaultImage.split(".")
    return formatSplited[formatSplited.length - 1]?.toLowerCase() === "pdf";
  }
}
